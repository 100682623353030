<template>
  <CustomBottomSheet
    :refName="'StudentsWalletTransactionReceiptReportFilter'"
    size="xl"
    :headerText="$t('Reports.receipt')"
    :headerIcon="icon"
    @opened="setFilter()"
  >
    <div class="report-container">
      <div class="row report-filter">
        <CustomInput
          :className="'col-md-12'"
          :id="`sendTo`"
          :value="userWalletTransactions.filterData.sendTo"
          v-on:changeValue="userWalletTransactions.filterData.sendTo = $event"
          :title="$t('Reports.sendTo')"
          :imgName="'email.svg'"
        />
      </div>
      <div
        v-if="reportUrl"
        class="row report-result animate__animated animate__fadeInDownBig"
      >
        <embed
          type="application/pdf"
          :src="reportUrl"
          width="400"
          height="300"
          id="pdf"
        />
      </div>
    </div>

    <div class="form-actions">
      <div class="icon-submit" @click.prevent="getReport">
        <img
          src="@/assets/images/check-icon.svg"
          :title="$t('Reports.print')"
        />
      </div>
      <div
        @click.prevent="
          closeBottomSheet('StudentsWalletTransactionReceiptReportFilter')
        "
        class="icon-cancel"
      >
        <img src="@/assets/images/cancel-icon.svg" :title="$t('cancel')" />
      </div>
    </div>
  </CustomBottomSheet>
</template>

<script>
import CustomBottomSheet from "@/components/general/CustomBottomSheet.vue";
import CustomInput from "@/components/general/CustomInput.vue";
import generalMixin from "@/utils/generalMixin";
import icon from "@/assets/images/receipt.svg";
import { STATUS } from "@/utils/constants";
import UserWalletTransactions from "./../../../models/settings/settingsOthers/userWalletTransactions/UserWalletTransactions";
import apiUserWalletTransaction from "@/api/userWalletTransaction";
import { getFullFilePathFromServer } from "./../../../utils/functions";

export default {
  mixins: [generalMixin],
  data() {
    return {
      reportUrl: "",
      isLoading: false,
      userWalletTransactions: new UserWalletTransactions(),
      icon,
    };
  },
  components: {
    CustomBottomSheet,
    CustomInput,
  },
  props: {
    token: {
      type: String,
      default: "",
    },
  },
  methods: {
    setFilter() {
      this.reportUrl = "";
      this.userWalletTransactions.filterData.setInitialValue();
      this.userWalletTransactions.filterData.token = this.token;
    },

    async getReport() {
      this.$emit("isLoading", true);
      this.reportUrl = "";
      try {
        const response =
          await apiUserWalletTransaction.getUserWalletTransactionReceiptReport(
            this.userWalletTransactions.filterData
          );
        if (response.data.status == STATUS.SUCCESS) {
          if (response.data.reportPath) {
            this.reportUrl = getFullFilePathFromServer(
              response.data.reportPath
            );
          } else {
            this.showMsg(this.$t("Reports.noReports"));
          }
        } else {
          this.showMsg(response.data.msg);
        }
      } catch (error) {
        this.showMsg(this.$t("errorCatch"));
      }
      this.$emit("isLoading", false);
    },
    async created() {},
  },
};
</script>
